<template>
  <el-container>
    <el-header><NavMenu /></el-header>
    <el-main><router-view /></el-main>
    <el-footer><Footer></Footer></el-footer>
  </el-container>
</template>
<!-- <script charset="utf-8" src="https://map.qq.com/api/js?v=2.exp&key=2QSBZ-GPXKW-CP3RQ-RS4JI-7TZ5T-45BOQ"></script>  -->
<script>
import NavMenu from "./components/NavMenu";
import Footer from "./components/Footer";
export default {
  name: "Layout",
  components: {
    NavMenu,
    Footer,
  },
};
</script>

<style lang="scss">
.el-container{
  width:100%;
  height: 100%;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
.el-tabs__active-bar {
  background-color: transparent !important;
}
/*去掉tabs底部的下划线*/
.el-tabs__nav-wrap::after {
  position: static !important;
}
.el-header,
.el-footer {
  background-color: #22352f;
  color: #fff !important;
  text-align: center;
  // line-height: 60px;
  padding: 0;
  height: 85px !important;
  // margin-top: 10px;
}
.el-main {
  background-color: #fff;
  color: #333;
  text-align: center;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
.el-card__body,
.el-main {
  padding: 0 !important;
  // margin-top: 20px;
}
.el-tabs__item.is-top {
  font-size: 20px;
}
</style>