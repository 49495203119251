<template>
  <div class="foot-box">
    <div class="footTop">
      <span>版权所有</span>
      <span>Copyright © 2022 福建盛冠信息科技有限公司</span>|
      <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备17025322号-2</a>
    </div>
    <div class="footBot">
      <span>地址：福州市晋安区新店镇坂中路6号泰禾城市广场2#楼16层1608室</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.foot-box {
  height: 85px;
  // line-height: 85px;
  display: flex;
  flex-direction: column;
  justify-content:center ;
  .footTop{
    span{
      margin-right: 10px;
    }
  }
  div{
    height: 25px;
  }
  span{
    font-size: 14px;
    color: #d8d9d8;
  }
  a{
    font-size: 14px;
    color: #d8d9d8;
    text-decoration: none;
  }
}
</style>