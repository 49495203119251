import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
// import { resolve } from 'core-js/fn/promise'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: resolve => require(['@/views/home'], resolve),
      },
      {
        path: 'service',
        name: 'service',
        // redirect: '/home',
        component: resolve => require(['@/views/service'], resolve),
      },
      {
        path: 'recruitment',
        name: 'recruitment',
        component: resolve => require(['@/views/recruitment'], resolve),
      },
      {
        path: 'about',
        name: 'about',
        component: resolve => require(['@/views/about'], resolve),
      },
      {
        path: 'relation',
        name: 'relation',
        component: resolve => require(['@/views/relation'], resolve),
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})
// 前置守卫
// router.beforeEach((to, from, next) => {
//   console.log(to.path, from.path)
//   if (from.path.toString != '/home') {
//     next('/home')
//     // alert(`已在目标页`)
//   } else {
//     next()
//   }
//   // next()
// })
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
