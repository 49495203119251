import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import ElementUI from 'element-ui'
import { Button, Tabs, Carousel, CarouselItem, TabPane, Main, Footer, Container, Image } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
// Vue.use(ElementUI)
Vue.config.productionTip = false
import BaiduMap from 'vue-baidu-map'
Vue.use(Button)
Vue.use(Tabs)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(TabPane)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Container)
Vue.use(Image)
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'fWNUqKDg0ttGabIovuX57i2BX38hjTVe'
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
