<template>
  <div class="nav-box">
    <div class="nav-left">
      <div class="block">
        <img src="@/assets/home/logo.png" alt="" />
      </div>
      <span></span>
      <div class="company"><p>福建盛冠信息科技有限公司</p></div>
    </div>
    <div class="nav-right">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="首页" name="first"></el-tab-pane>
        <el-tab-pane label="产品服务" name="second"></el-tab-pane>
        <el-tab-pane label="诚聘英才" name="third"></el-tab-pane>
        <el-tab-pane label="关于我们" name="fourth"></el-tab-pane>
        <el-tab-pane label="联系我们" name="fifth"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavMenu",
  watch: {
    $route(to, from) {
      console.log("路由变化了");
      console.log("当前页面路由：" + to.path);
      console.log("上一个路由：" + from);
      if (to.path == "/service") {
        this.activeName = 'second'
      }
    },
  },
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      activeName: "first",
      url: "@/assets/logo.png",
    };
  },

  methods: {
    handleClick(tab, event) {
      console.log(tab, "tab");
      localStorage.setItem("activeName", tab.name);
      this.$router.push(this.$router.options.routes[0].children[tab.index]);
    },
  },
  created() {
    // this.activeName =  localStorage.getItem("activeName") ?  localStorage.getItem("activeName"):"first"
    // console.log(localStorage.getItem("activeName"));
  },
};
</script>
<style lang="scss" scoped>
.nav-box {
  // height: 70px;
  display: flex;
  justify-content: space-between;
  .nav-left {
    display: flex;
    margin-left: 25px;
    align-items: center;
    span {
      width: 5px;
      height: 45px;
      background-color: #009966;
      margin-left: 15px;
    }
    .company {
      font-weight: 600;
      color: #24ac6f;
      font-size: 18px;
      margin-left: 10px;
    }
    .block {
      img {
        width: 65px;
        height: 59px;
      }
    }
  }
  //   导航栏右侧
  .nav-right {
    // height: 100%;
    line-height: 84px;
    margin-right: 30px;
    .el-tabs__item,
    div {
      font-size: 25px;
      color: #1e1e1e;
      font-weight: 400;
    }
  }
}
</style>